<template>
  <div>
    <Navgation />
    <div class="container">
      <div class="breadcrum">
        <div class="nav">
          <span class="title" @click="routeTo('home')">首页</span>
          <svg-icon icon-class="right-arrow" class-name="icon"></svg-icon>
          <span class="title" @click="routeTo('news')">资讯动态</span>
          <svg-icon icon-class="right-arrow" class-name="icon"></svg-icon>
          <span class="title active">动态详情</span>
        </div>
      </div>
      <div class="panel">
        <div class="title">{{ news.title }}</div>
        <div class="info">
          <span class="time"
            >发布时间：<span class="font">{{ news.time }}</span></span
          >
          <span class="read">{{ news.read }}人阅读</span>
        </div>
        <div class="content" v-html="article"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsList, newsArticle } from '@/config'
import Navgation from '@/layouts/components/Navgation'
import router from '@/router'

export default {
  name: '',
  filters: {},
  components: {Navgation},
  props: {},
  data() {
    return {
      newsArticle,
      newsList,
    }
  },
  computed: {
    article() {
      const articleIdx = this.$route.query.article || 0
      return this.newsArticle[articleIdx]
    },
    news() {
      const articleIdx = this.$route.query.article || 0
      return this.newsList[articleIdx]
    },
  },
  watch: {},
  mounted() {
    document.querySelector('meta[name="keywords"]').setAttribute('content',this.article.slice(50,55) + ',' + this.article.slice(60,65) + ',' + this.article.slice(70,75) )
    document.querySelector('meta[name="description"]').setAttribute('content', this.article.slice(0,40))
    document.title = this.news.title;
  },
  created() {},
  methods: {
    routeTo(path) {
      this.$router.push({ path })
    },
  },
}
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .container {
     margin-top: @navHeight;
    .breadcrum {
      width: 100%;
      height: 64px;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
      border: 2px solid #ffffff;
      display: flex;
      align-items: center;
      .nav {
        width: @panelWidth;
        margin: 0 auto;
        .title {
          font-size: 14px;
          color: #949da8;
          line-height: 20px;
          font-family: AlibabaPuHuiTiR;
          cursor: pointer;
        }
        .icon {
          width: 9px;
          height: 9px;
          line-height: 15px;
          vertical-align: middle;
          margin: 0 4px;
        }
        .active {
          color: #2761ff;
        }
      }
    }
    .panel {
      width: @panelWidth;
      margin: 0 auto;
      text-align: center;
      .title {
        font-size: 24px;
        font-family: AlibabaPuHuiTiM;
        color: #000000;
        line-height: 33px;
        margin: 72px 0 55px 0;
      }
      .info {
        margin-bottom: 26px;
        text-align: center;
        .time {
          font-size: 14px;
          color: #86909c;
          font-family: AlibabaPuHuiTiR;
          line-height: 24px;
          .font {
            color: #000000;
            font-family: AlibabaPuHuiTiM;
          }
        }
        .read {
          padding-left: 36px;
          font-size: 14px;
          font-family: AlibabaPuHuiTiR;
          color: #86909c;
          line-height: 24px;
        }
      }
      .content {
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        color: #717a85;
        line-height: 28px;
        margin-bottom: 192px;
        text-align: left;
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .breadcrum {
      height: 90px;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
      border: 2px solid #ffffff;
      display: flex;
      align-items: center;
      .nav {
        padding: 0 40px;
        display: flex;
        align-items: center;
        .title {
          font-size: 24px;
          color: #949da8;
          line-height: 33px;
          cursor: pointer;
        }
        .icon {
          width: 15px;
          height: 15px;
          margin: 0 15px;
        }
        .active {
          color: #2761ff;
        }
      }
    }
    .panel {
      padding: 44px 40px 88px 40px;
      .title {
        font-size: 32px;
        color: #000000;
        line-height: 51px;
        margin-bottom: 25px;
        font-weight: bold;
      }
      .info {
        padding-bottom: 44px;
        border-bottom: 1px solid #e3e8ee;
        .time {
          font-size: 20px;
          color: #86909c;
          line-height: 24px;
          .font {
            color: #000000;
            font-weight: 500;
          }
        }
        .read {
          padding-left: 52px;
          font-size: 20px;
          color: #86909c;
          line-height: 24px;
        }
      }
      .content {
        font-size: 24px;
        color: #717a85;
        line-height: 48px;
        padding-top: 25px;
      }
    }
  }
}
</style>
