<template>
  <section class="section">
    <div class="contact-us-wrapper">
      <div class="title center">{{ contactUsInfo.title }}</div>
      <div class="desc center">{{ contactUsInfo.description }}</div>
      <div class="contact-us m-auto">
        <form>
          <input type="text" name="companyName" role="input" placeholder="请输入公司名称" v-model.trim="form.qyName" />
          <input type="text" name="companyName" role="input" placeholder="请输入手机号码" v-model.trim="form.mobile" />
          <div class="verificat-code">
            <input type="text" name="companyName" role="input" placeholder="请输入验证码" v-model.trim="form.smsCode" />
            <button type="button" role="verificat-code-btn" @click.prevent="getMsgCode">{{ sendMsg ? count + 's' : '获取验证码' }}</button>
          </div>
        </form>
        <button class="contact-btn" @click="handleSubmit">
          立即体验
          <div class="mask"></div>
        </button>
      </div>
    </div>
    <Message ref="message" />
    <modal v-show="visible" @close="visible = false">
      <div class="yy-success">
        <svg-icon url="yy-success" class="left"></svg-icon>
        <div class="right">您已成功预约产品体验，稍后将有专属客服与您电话联系</div>
      </div>
    </modal>
  </section>
</template>

<script>
import { contactUsInfo } from '@/config'
import Message from '@/components/message'
import Modal from '@/components/modal'
export default {
  name: 'ContractUs',
  computed: {
    contactUsInfo() {
      return contactUsInfo
    },
  },
  data() {
    return {
      visible: false,
      count: 60,
      timer: null,
      sendMsg: false,
      btnLoading: false,
      form: {
        qyName: '',
        mobile: '',
        smsCode: '',
      },
    }
  },
  mounted() {
    const value = localStorage.getExpire('sendMsgCount')
    if (value) {
      this.sendMsg = true
      this.count = value
      this.timer = setInterval(() => {
        localStorage.setExpire('sendMsgCount', this.count, this.count * 1000)
        this.count--
        if (this.count === 0) {
          clearInterval(this.timer)
          this.timer = null
          this.sendMsg = false
          this.count = 60
          localStorage.setExpire('sendMsgCount', this.count, 0)
        }
      }, 1000)
    }
  },
  components: {
    Message,
    Modal,
  },
  methods: {
    // 获取验证码
    async getMsgCode() {
      try {
        if (this.sendMsg || this.btnLoading) return
        if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.mobile)) {
          this.$refs.message.alert('请输入正确格式的手机号', 'warning', 1500)
          return
        }
        this.sendMsg = true
        this.btnLoading = true
        const res = await this.$axios.get(`https://autodz.qyunf.com/api/fintax/application/auto/common/sms/getSmsCode/${this.form.mobile}`)
        const { code, message } = res.data
        if (code == 0 && message == '成功') {
          this.timer = setInterval(() => {
            localStorage.setExpire('sendMsgCount', this.count, this.count * 1000)
            this.count--
            if (this.count === 0) {
              clearInterval(this.timer)
              this.timer = null
              this.sendMsg = false
              this.count = 60
              localStorage.setExpire('sendMsgCount', this.count, 0)
            }
          }, 1000)
          this.$refs.message.alert('验证码已发送，请注意查收', 'success', 1500)
        }
      } catch (error) {
        clearInterval(this.timer)
        this.timer = null
        this.sendMsg = false
        this.count = 60
        localStorage.setExpire('sendMsgCount', this.count, 0)
      } finally {
        this.btnLoading = false
      }
    },
    // 提交接口
    async handleSubmit() {
      if (!this.form.qyName) {
        this.$refs.message.alert('请输入企业名称', 'warning', 1500)
        return
      }
      if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(this.form.mobile)) {
        this.$refs.message.alert('请输入正确手机号码', 'warning', 1500)
        return
      }
      if (!/^\d{6}$/.test(Number(this.form.smsCode))) {
        this.$refs.message.alert('请输入正确短信验证码', 'warning', 1500)
        return
      }
      try {
        this.btnLoading = true
        const res = await this.$axios.post('https://autodz.qyunf.com/api/fintax/application/auto/authority/user/out/saveExperienceRecord', this.form)
        const { code } = res.data
        if (code == 0) {
          // this.$refs.message.alert('您已成功预约产品体验，稍后将有专属客服与您电话联系！', 'success', 1500)
          this.visible = true
        } else if (code == 101) {
          this.$refs.message.alert('您输入的验证码有误！', 'error', 3000)
        }
      } catch (error) {
        this.$refs.message.alert('申请失败，请重新申请', 'error', 3000)
      } finally {
        this.btnLoading = false
      }
    },
  },
}
</script>

<style scoped lang="less">
.contact-us-wrapper {
  padding-bottom: 120px;
  background-image: url('~@/assets/images/grid-backgound.png');
  background-repeat: no-repeat;
  background-size: 100% 350px;
  background-position: bottom;
  .title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 38px;
    padding-top: 72px;
    padding-bottom: 16px;
  }
  .desc {
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: #86909c;
    line-height: 20px;
    padding-bottom: 26px;
  }
  .contact-us {
    width: 760px;
    [role='input'] {
      outline: none;
      display: block;
      width: calc(100% - 88px);
      margin-top: 32px;
      height: 64px;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
      border-radius: 100px;
      border: 1px solid #ffffff;
      font-size: 20px;
      padding: 0 44px;
      &::placeholder {
        color: #86909c;
        letter-spacing: 1px;
      }
      &:hover {
        border-color: #2761ff;
      }
    }
    .verificat-code {
      display: flex;
      margin-top: 32px;
      input {
        flex: 1;
        margin-top: 0;
      }
      button {
        cursor: pointer;
        color: #2761ff;
        background: linear-gradient(180deg, rgba(39, 97, 255, 0.28) 0%, rgba(39, 97, 255, 0.08) 100%);
        box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
        border: 1px solid #2761ff;
        margin-left: 12px;
        width: 208px;
        text-align: center;
        border-radius: 100px;
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #2761ff;
      }
    }
    .contact-btn {
      cursor: pointer;
      display: block;
      width: 100%;
      margin-top: 32px;
      padding: 18px 0;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      background: #2761ff;
      box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
      border: 1px solid #2761ff;
      border-radius: 100px;
      font-size: 20px;
      font-family: AlibabaPuHuiTiR;
      color: #ffffff;
      position: relative;
      .mask {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.1;
        display: none;
      }
      &:hover {
        .mask {
          display: block;
        }
      }
    }
  }
}
.yy-success {
  width: 444px;
  height: 132px;
  background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  .left {
    width: 112px;
    position: relative;
    top: 10px;
  }
  .right {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    padding-right: 36px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .contact-us-wrapper {
    margin-bottom: 90px;
    .title {
      font-size: 28px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 38px;
      padding-top: 88px;
    }
    .desc {
      font-size: 18px;
      line-height: 25px;
    }
    .contact-us {
      width: 670px;
      [role='input'] {
        font-size: 20px;
        width: calc(100% - 88px);
        height: 72px;
        padding: 0 44px;
        &::placeholder {
          color: #86909c;
          letter-spacing: 1px;
        }
        &:hover {
          border-color: #2761ff;
        }
      }
      .verificat-code {
        display: flex;
        margin-top: 24px;
        input {
          flex: 1;
          margin-top: 0;
        }
        button {
          font-size: 20px;
          margin-left: 20px;
          width: 208px;
          border-radius: 100px;
        }
      }
      .contact-btn {
        margin-top: 24px;
        padding: 20px 0;
        font-size: 20px;
      }
    }
  }
}
</style>
