<template>
  <div class="jumbotron-container" :style="{ backgroundImage: `url(${bgUrl})` }"></div>
</template>

<script>
export default {
  name: 'Jumbotron',
  filters: {},
  components: {},
  props: {
    url: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    bgUrl() {
      return require(`@/assets/images/${this.url}.png`)
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style scoped lang="less">
.jumbotron-container {
  position: relative;
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .jumbotron {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    // .title {
    //   font-size: 26px;
    //   color: #fff;
    //   font-weight: bold;
    // }
    // .line {
    //   height: 4px;
    //   width: 4px;
    //   border-radius: 50%;
    //   box-shadow: 0px 0px 10px 0px #fff;
    //   position: relative;
    //   &::after {
    //     position: absolute;
    //   }
    // }
    // .sub-title {
    //   font-size: 14px;
    //   color: #fff;
    //   font-family: sans-serif;
    // }
  }
}
</style>
