<template>
  <section class="section">
    <div class="empty-block" v-if="!isMobile"></div>
    <div class="step-wrapper">
      <div class="title center">{{ stepInfo.title }}</div>
      <div class="step">
        <img :src="stepInfo.stepBg" class="img-responsive step-bg" />
        <div class="step-inner">
          <div class="step-inner-one" v-for="(item, index) in stepInfo.steps" :key="item.key">
            <svg-icon :url="item.icon" class-name="step-inner-one-icon"></svg-icon>
            <div class="step-inner-one-r">
              <div class="step-inner-one-r-title">
                {{ item.title }}
              </div>
              <div class="step-inner-one-r-desc">
                {{ isMobile ? `（${item.act}）` : item.act }}
              </div>
            </div>
            <div class="step-inner-one-line m-auto" v-if="index < 3 && !isMobile"></div>
            <svg-icon icon-class="step-arrow-down" v-if="index < 3 && isMobile" class="step-arrow-down"></svg-icon>
          </div>
        </div>
      </div>
      <div class="step-customer-voice" v-if="!isMobile">
        <div class="step-customer-voice-title">{{ stepInfo.customerTitle }}</div>
        <div class="step-customer-voice-slide m-auto">
          <swiper ref="homeSwiper" :options="swiperOptions">
            <swiper-slide v-for="item in stepInfo.customerMsg" :key="item.key">
              <div class="slide-one">
                <svg-icon :icon-class="item.avatar" class-name="slide-one-icon"></svg-icon>
                <div class="slide-one-r">
                  <div class="slide-one-r-customer">{{ item.customer }}</div>
                  <div class="slide-one-r-msg">{{ item.msg }}</div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="step-customer-voice-pagination">
          <svg-icon :url="stepInfo.customerLeftIcon" class-name="pagination-icon" @click="prev"></svg-icon>
          <svg-icon :url="stepInfo.customerRightIcon" class-name="pagination-icon" @click="next"></svg-icon>
        </div>
      </div>
      <div v-else class="m-step-customer-voice">
        <div class="m-step-customer-voice-title">{{ stepInfo.customerTitle }}</div>
        <swiper class="m-swiper" :options="mSwiperOption" key="mSwiper">
          <swiper-slide v-for="item in stepInfo.customerMsg" :key="item.key">
            <div class="m-slide-con">
              <div class="m-company">
                <svg-icon icon-class="case-user-compony" class-name="m-icon"></svg-icon>
                <span class="m-name">{{ item.customer }}</span>
              </div>
              <div class="m-content">{{ item.msg }}</div>
            </div>
          </swiper-slide>
          <div class="m-swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { stepInfo } from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'
export default {
  name: 'Steps',
  mixins: [checkIsMobile],
  data() {
    return {
      swiper: null,
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 20,
      },
      mSwiperOption: {
        effect: 'coverflow',
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 200,
          depth: 100,
          slideShadows: false,
          modifier: 1,
        },
        pagination: {
          el: '.m-swiper-pagination',
          type: 'custom',
          renderCustom: (swiper, current) => {
            const html = `<div class="m-step-list">
            <span class="step-item ${current === 1 && 'active'}"></span>
            <span class="step-item ${current === 2 && 'active'}"></span>
            <span class="step-item ${current === 3 && 'active'}"></span>
            </div>`
            return html
          },
        },
      },
    }
  },
  computed: {
    stepInfo() {
      return stepInfo
    },
  },
  mounted() {},
  watch: {},
  methods: {
    prev() {
      this.$refs.homeSwiper.$swiper.slidePrev()
    },
    next() {
      this.$refs.homeSwiper.$swiper.slideNext()
    },
  },
}
</script>

<style scoped lang="less">
.empty-block {
  height: 100px;
  overflow: hidden;
  width: 100%;
}
.step-wrapper {
  .title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 38px;
    padding-bottom: 38px;
    padding-top: 72px;
  }
}
.step {
  // width: 2560px;
  position: relative;
  &-bg {
    min-height: 204px;
  }
  &-inner {
    position: absolute;
    width: @panelWidth;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    &-one {
      flex: 1;
      display: flex;
      align-items: center;
      &-icon {
        width: 75px;
      }
      &-r {
        margin-left: 16px;
        position: relative;
        &-title {
          font-size: 20px;
          font-family: AlibabaPuHuiTiR, sans-serif;
          color: #ffffff;
          font-weight: lighter;
          line-height: 27px;
        }
        &-desc {
          margin-top: 12px;
          border-radius: 2px;
          padding: 5px 13px;
          display: inline-block;
          border: 1px solid rgba(255, 255, 255, 0.64);
          font-size: 16px;
          color: #ffffff;
          font-family: sans-serif;
          font-weight: lighter;
          opacity: 0.7;
        }
      }
      &:last-child {
        flex: none;
      }
      &-line {
        flex: 1;
        max-width: 70px;
        border-bottom: 1px dashed #ffffff;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: -2px;
          height: 5px;
          width: 5px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 10px 2px #ffffff;
        }
      }
    }
  }
}
.step-customer-voice {
  width: 100vw;
  overflow: hidden;
  margin-top: -2px;
  background-image: url('../assets/images/step-custom-voice-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 485px;
  box-sizing: border-box;
  &-title {
    position: relative;
    left: 46%;
    font-family: AlibabaPuHuiTiM;
    color: #ffffff;
    padding-top: 72px;
    padding-bottom: 50px;
    display: inline-block;
    font-size: 28px;
    line-height: 38px;
  }
  &-slide {
    width: 1560px;
    max-width: 1560px;
    transform: translateX(350px);
    .slide-one {
      background-color: #fff;
      width: 500px;
      min-height: 240px;
      display: flex;
      background-image: url('../assets/images/step-customer-voice-slide-one-bg.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
      border-radius: 2px;
      border: 2px solid #ffffff;
      box-sizing: border-box;
      &-icon {
        font-size: 28px;
      }
      &-r {
        flex: 1;
        &-customer {
          margin-top: 40px;
          margin-left: 72px;
          margin-right: 40px;
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 27px;
        }
        &-msg {
          margin-top: 12px;
          margin-left: 72px;
          margin-right: 40px;
          font-size: 14px;
          font-family: AlibabaPuHuiTiR;
          color: #86909c;
          line-height: 20px;
        }
      }
    }
  }
  &-pagination {
    display: inline-block;
    position: relative;
    left: 46%;
    .pagination-icon {
      cursor: pointer;
      max-width: 75px;
      margin-left: -15px;
      margin-top: 7px;
      &:hover {
        filter: contrast(0.9);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .step-wrapper {
    .title {
      font-size: 36px;
      line-height: 50px;
      padding-top: 88px;
      padding-bottom: 44px;
    }
    .step {
      width: 100%;
      background-image: url('../assets/images/step-bg-m.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      & > img {
        display: none;
      }
      &-inner {
        position: relative;
        top: inherit;
        left: inherit;
        transform: inherit;
        width: inherit;
        flex-wrap: wrap;
        padding: 44px 0;
        &-one {
          margin: 0px 115px;
          padding: 20px 70px;
          flex: 100%;
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.16);
          border-radius: 2px;
          border: 1px solid #ffffff;
          backdrop-filter: blur(4px);
          &:not(:first-child) {
            margin-top: 36px;
          }
          &-icon {
            font-size: 60px;
          }
          &-r {
            margin-left: 16px;
            position: relative;
            display: flex;
            align-items: center;
            &-title {
              white-space: nowrap;
              font-size: 24px;
              font-family: AlibabaPuHuiTiR;
              color: #ffffff;
            }
            &-desc {
              white-space: nowrap;
              color: #ffffff9e;
              font-size: 24px;
              margin-top: inherit;
              border: none;
              border-radius: inherit;
            }
          }
          &:last-child {
            flex: 100%;
          }
          .step-arrow-down {
            left: 50%;
            font-size: 24px;
            color: #ffffff;
            position: absolute;
            transform: translateX(-50%);
            bottom: -30px;
          }
        }
      }
    }

    .m-step-customer-voice {
      background-color: #182030;

      &-title {
        font-size: 28px;
        font-family: AlibabaPuHuiTiM;
        color: #ffffff;
        line-height: 38px;
        padding-top: 88px;
        padding-bottom: 44px;
        text-align: center;
      }
      .m-swiper {
        width: calc(750px - 100px);
        margin: 0 auto;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 520px;
          text-align: center;
        }
        .m-slide-con {
          // width: 436px;
          height: 360px;
          box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
          border-radius: 2px;
          border: 2px solid #ffffff;
          background-image: url('../assets/images/step-customer-voice-slide-one-bg.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 44px;
          .m-company {
            display: flex;
            margin-bottom: 16px;
            .m-icon {
              width: 36px;
              height: 42px;
            }
            .m-name {
              color: #000000;
              font-weight: bold;
              font-size: 24px;
              font-family: AlibabaPuHuiTiM;
              color: #000000;
              line-height: 33px;
              margin-left: 32px;
            }
          }
          .m-content {
            text-align: left;
            font-size: 20px;
            font-family: AlibabaPuHuiTiR;
            color: #86909c;
            line-height: 32px;
            margin-left: 56px;
          }
        }
        /deep/ .m-step-list {
          display: flex;
          justify-content: center;
          padding: 44px 0;
          .step-item {
            width: 52px;
            height: 3px;
            background: #2761ff;
            opacity: 0.31;
          }
          .step-item:not(:first-child) {
            margin-left: 12px;
          }
          .active {
            opacity: 1;
            background-color: #ffffff !important;
          }
        }
      }
    }
  }
}
</style>
