<template>
  <section class="section" style="position: relative">
    <div class="banner-wrapper">
      <div class="banner m-auto">
        <div class="banner-l">
          <div class="title">{{ bannerInfo.title }}</div>
          <div class="sub-title">{{ bannerInfo.subTitle }}</div>
          <div class="btn-gp">
            <span class="btn btn-l" @click="openQrVisible"
              >扫码下载
              <svg-icon
                icon-class="banner-btn-l"
                class="banner-btn-icon"
              ></svg-icon>
              <div class="btn-mask"></div>
            </span>
            <span class="btn btn-r" v-show="false"
              >观看视频
              <svg-icon
                icon-class="banner-btn-r"
                class="banner-btn-icon"
              ></svg-icon>
              <div class="btn-mask"></div>
            </span>
          </div>
        </div>
        <div class="banner-r">
          <div class="banner-r-plug">
            <svg-icon url="banner-r-l" class-name="banner-r-l"></svg-icon>
            <svg-icon
              url="banner-r-main"
              class-name="banner-r-plug-main"
            ></svg-icon>
            <svg-icon url="banner-r-r" class-name="banner-r-plug-r"></svg-icon>
            <svg-icon
              url="banner-r-shadow-1"
              class-name="banner-r-shadow-1 banner-r-shadow"
            ></svg-icon>
            <svg-icon
              url="banner-r-shadow-2"
              class-name="banner-r-shadow-2 banner-r-shadow"
            ></svg-icon>
            <svg-icon
              url="banner-r-shadow-3"
              class-name="banner-r-shadow-3 banner-r-shadow"
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="text_box">
      <i></i
      >响应税局税收体制体系改革，专为小规模企业量身打造，倡导企业用户共同参与自助便捷的记账申报服务
    </div>
    <modal v-show="visible" @close="visible = false"> </modal>
    <!-- 登录二维码 -->
    <QrCodeModal
      :QrCodeDialogVisible="qrVisible"
      @closeQr="closeQr"
      :qrUrl="qrUrl"
      v-if="qrVisible"
    >
    </QrCodeModal>
    <!-- 起名结果 -->
    <TheNameResultModal
      :theNameResultVisible="theNameResultVisible"
      @closeTheNameResult="closeTheNameResult"
    >
    </TheNameResultModal>
    <!-- 核名结果 -->
    <NameCheckResult
      :nameCheckResultVisible="nameCheckResultVisible"
      @closeNameCheckResult="closeNameCheckResult"
      :hmParams="hmParams"
    >
    </NameCheckResult>
    <!-- 测名结果 -->
    <CmResult
      :cmResultVisible="cmResultVisible"
      @closeCmResult="closeCmResult"
      :cmParams="cmParams"
    >
    </CmResult>
  </section>
</template>

<script>
import { bannerInfo } from '@/config'
import Modal from '@/components/modal'
import QrCodeModal from '@/views/homePopup/qrCode.vue'
import TheNameResultModal from '@/views/homePopup/theNameResult.vue'
import NameCheckResult from '@/views/homePopup/nameCheckResult.vue'
import CmResult from '@/views/homePopup/cmResult.vue'
import homeApi from '@/api/home.api.js'
import auth from '@/utils/auth.js'
export default {
  name: 'Banner',
  computed: {
    bannerInfo() {
      return bannerInfo
    },
  },
  data() {
    return {
      visible: false,
      qrVisible: false, //二维码弹窗开关
      theNameResultVisible: false, //起名结果弹窗开关
      nameCheckResultVisible: false, //核名结果弹窗开关
      cmResultVisible: false, //测名结果弹窗开关
      qrUrl: '',
      interval: null,
      cmParams: {
        address: '', //地址
        city: '', //市
        companyName: '', //企业全称
        district: '', //区
        industry: '', //行业
        industryName: '', //行业名称
        province: '', //省
        words: '', //关键字
      },
      hmParams: {
        city: '', //城市
        cityId: '', //市代码
        company: '', //组织形式
        companyName: '', //企业全称
        companyType: 0, //2 有限公司
        district: '', //区
        hotword: '', //字号
        industry: '', //行业类型
        industryCode: '', //行业编码
        industryId: 0,
        mobile: '', //手机号
        openid: '', //公众号openId
        province: '', //省
        street: '', //街道
        tenant: '', //租户
      },
    }
  },
  created() {
    // this.getQr()
    // this.getAllNodeTree()
    // this.getIndustry()
    // this.companyQm()
  },
  methods: {
    openQrVisible() {
      this.getQr()
    },
    closeQr() {
      this.qrVisible = false
    },
    closeTheNameResult(item) {
      this.theNameResultVisible = false
      this.cmResultVisible = true
      this.cmParams = {
        address: '',
        city: '北京',
        companyName: item.companyName,
        district: '',
        industry: '网络科技',
        industryName: '',
        province: '',
        words: item.words,
      }
    },
    closeNameCheckResult() {
      this.nameCheckResultVisible = false
    },
    closeCmResult(cmResult, cmParams) {
      this.cmResultVisible = false
      this.nameCheckResultVisible = true
      this.hmParams = {
        city: cmParams.city, //城市
        cityId: '', //市代码
        company: '', //组织形式
        companyName: cmParams.companyName, //企业全称
        companyType: '', //2 有限公司
        district: '', //区
        hotword: '', //字号
        industry: cmParams.industry, //行业类型
        industryCode: '', //行业编码
        industryId: '',
        mobile: '', //手机号
        openid: '', //公众号openId
        province: cmParams.province, //省
        street: '', //街道
        tenant: '', //租户
      }
    },
    async getQr() {
      const res = await homeApi.getGwQrCode()
      if (!res.result) {
        this.$message.error('获取小程序二维码失败')
      }
      this.qrVisible = true
      this.qrUrl = res.result.url
      auth.saveLoginId(res.result.code)
      this.interval = setInterval(() => {
        this.getLoginInfo({ code: auth.getLoginId() })
      }, 10000)
    },
    async getLoginInfo(params) {
      const res = await homeApi.getLoginInfo(params)
      if (res.result.loginFlag) {
        auth.saveAccessToken(res.result.accessToken)
        document.cookie = 'access_token=' + res.result.accessToken
        window.clearInterval(this.interval)
        this.interval = null
        this.closeQr()
        this.theNameResultVisible = true
      }
    },
    async getAllNodeTree() {
      const res = await homeApi.getAllNodeTree()
      console.log('城市', res)
    },
    async getIndustry() {
      const res = await homeApi.getIndustry()
      console.log('行业', res)
    },
  },
  components: {
    Modal,
    QrCodeModal,
    TheNameResultModal,
    NameCheckResult,
    CmResult,
  },
}
</script>

<style scoped lang="less">
.banner-wrapper {
  background-image: url('../assets/images/banner.jpg');
}
.banner {
  width: @panelWidth;
  display: flex;
  min-height: 560px;
  &-l {
    flex: 1;
    padding-top: 145px;
    padding-bottom: 237px;
    .title {
      font-size: 40px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 55px;
    }
    .sub-title {
      padding-top: 16px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiL;
      color: #000000;
      line-height: 28px;
      width: 508px;
    }
    .btn {
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      width: 152px;
      height: 44px;
      line-height: 44px;
      border-radius: 100px;
      text-align: center;
      display: inline-block;
      margin-top: 24px;
      font-size: 18px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      background-color: #f3f6f8;
      border: 1px solid #ffffff;
      .btn-mask {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: transparent;
        opacity: 0.2;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
    .btn-l {
      color: #ffffff;
      background-color: #2761ff;
      border-color: #2761ff;
      box-shadow: 0 0px 10px 0px #2761ffad;
    }
    .btn-r {
      margin-left: 12px;
      box-shadow: 0 0px 10px 0px #ffffff;
    }
    .banner-btn-icon {
      font-size: 24px;
      position: relative;
      top: 3px;
    }
  }
  &-r {
    flex: 1;
    position: relative;
    &-shadow {
      position: absolute;
      &-1 {
        bottom: 8px;
        left: 13px;
        transform: translateX(-50%);
        z-index: 1;
        animation: small 9s infinite ease-in-out;
      }
      &-2 {
        z-index: 1;
        bottom: 181px;
        right: -57px;
        width: 150px;
        animation: large 9s infinite ease-in-out;
      }
      &-3 {
        width: 150px;
        bottom: 171px;
        left: -51px;
        z-index: 1;
        animation: large 9s infinite ease-in-out;
      }
    }
    &-plug {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-main {
        z-index: 3;
        max-width: 468px;
        height: 453px;
        position: relative;
        animation: sway-top 9s infinite ease-in-out;
      }
      .banner-r-l {
        z-index: 2;
        width: 120px;
        position: absolute;
        left: -38px;
        top: 80px;
        animation: sway-bottom 9s infinite ease-in-out;
      }
      &-r {
        z-index: 2;
        width: 120px;
        position: absolute;
        right: -40px;
        top: 76px;
        overflow: hidden;
        animation: sway-bottom 9s infinite ease-in-out;
      }
    }
  }
}
@media (max-width: 768px) {
  .banner {
    display: flex;
    flex-direction: column;
    padding: 75px 0;
    width: auto;

    &-l {
      padding-top: 0px;
      padding-bottom: 0px;
      .title {
        padding-top: 137px;
        padding-bottom: 16px;
        font-size: 40px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        text-align: center;
        line-height: 55px;
      }
      .sub-title {
        text-align: center;
        padding-top: 16px;
        font-size: 22px;
        font-family: AlibabaPuHuiTiL;
        color: #000000;
        line-height: 36px;
        font-family: sans-serif;
        font-weight: lighter;
        line-height: 36px;
        margin: 0 auto;
      }
      .btn-gp {
        text-align: center;
      }
      .btn {
        margin-top: 30px;
        padding: 4px 10px;
        font-size: 22px;
        text-align: center;
        font-family: AlibabaPuHuiTiR;
      }
      .banner-btn-icon {
        font-size: 30px;
      }
    }
    &-r {
      min-height: 460px;
      margin-top: 35px;
      .banner-r-shadow-1 {
        width: 444px;
        bottom: -36px;
      }
      .banner-r-shadow-2 {
        right: -31px;
        bottom: 67px;
      }
      .banner-r-shadow-3 {
        left: -34px;
        bottom: 62px;
      }
      // &-plug {

      //   &-r {
      //     // font-size: 150px;
      //   }
      // }
      &-main {
        width: 513px;
        height: auto;
      }
      &-plug-r {
        width: 150px;
      }
      &-l {
        width: 150px;
      }
    }
  }
}
@keyframes sway-top {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes sway-bottom {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}

@keyframes small {
  0% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.6);
  }
}
@keyframes large {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.text_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 55px;
  background: rgba(255, 255, 255, 0.32);
  font-size: 20px;
  font-family: AlibabaPuHuiTiL;
  color: #000000;
  text-align: center;
  > i {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    // background-color: pink;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 30%,
      #ffffff 71%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>
