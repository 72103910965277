var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cm-result"},[_c('el-dialog',{attrs:{"title":"","visible":_vm.cmResultVisible,"destroy-on-close":"","custom-class":"cm-result-dialog","close-on-click-modal":false,"close-on-press-escape":false,"show-close":false},on:{"update:visible":function($event){_vm.cmResultVisible=$event}}},[_c('div',{staticClass:"cm-result-dialog-body"},[_c('img',{staticClass:"close-icon",attrs:{"src":require("@/assets/images/home/close.png"),"alt":""},on:{"click":() => {
            _vm.closeCmResult()
          }}}),_c('div',{staticClass:"title"},[_vm._v("测名结果")]),_c('div',{staticClass:"cm-result-content"},[_c('div',{staticClass:"cm-info"},[_c('div',{staticClass:"cm-dashboard-box"},[_c('el-progress',{staticClass:"cm-dashboard",attrs:{"type":"dashboard","percentage":_vm.score,"stroke-width":6,"show-text":false}}),_c('div',{staticClass:"score"},[_c('div',[_vm._v("评分")]),_c('div',[_vm._v(_vm._s(_vm.score))])]),_c('div',{staticClass:"level"},[_vm._v(_vm._s(_vm.analysisData.jx))])],1),_c('div',{staticClass:"cm-info-name"},[_vm._v(_vm._s(_vm.cmParams.companyName))]),(_vm.cmParams.city)?_c('div',{staticClass:"cm-info-btn",on:{"click":_vm.goHm}},[_vm._v(" 去核名 "),_c('img',{attrs:{"src":require("@/assets/images/home/go.png"),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"cm-analysis"},_vm._l((_vm.analysisList),function(item,index){return _c('div',{key:index,staticClass:"cm-analysis-item"},[_c('div',{staticClass:"cm-analysis-item-label"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"cm-analysis-item-content"},[_vm._v(" "+_vm._s(_vm.analysisData[item.code])+" ")])])}),0),_c('div',{staticClass:"name-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},_vm._l((_vm.columns),function(item){return _c('el-table-column',{key:item.code,attrs:{"prop":item.code,"label":item.label,"align":'center',"border":false}})}),1)],1),_c('div',{staticClass:"cm-unscramble"},[(_vm.unscrambleList.length >= 6)?_c('div',{staticClass:"header-line"}):_vm._e(),_c('div',{staticClass:"cm-unscramble-header"},_vm._l((_vm.unscrambleList),function(item,index){return _c('div',{key:index,staticClass:"cm-unscramble-header-item",class:{
                'active-cm-unscramble-header-item':
                  _vm.activeUnscramble === index,
              },on:{"click":function($event){_vm.activeUnscramble = index}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),(
              _vm.unscrambleList[_vm.activeUnscramble] &&
              _vm.unscrambleList[_vm.activeUnscramble].content
            )?_c('div',{staticClass:"cm-unscramble-content"},_vm._l((_vm.unscrambleList[_vm.activeUnscramble]
                .content),function(cItem,cIndex){return _c('p',{key:'cItem' + cIndex},[_vm._v(" "+_vm._s(cItem)+" ")])}),0):_vm._e()])])])]),_c('svg',{attrs:{"width":"100%","height":"100%"}},[_c('defs',[_c('linearGradient',{attrs:{"id":"write-svg","x1":"0%","y1":"0%","x2":"100%","y2":"0%"}},[_c('stop',{staticStyle:{"stop-color":"rgba(40, 113, 255, 1)"},attrs:{"offset":"0%"}}),_c('stop',{staticStyle:{"stop-color":"rgba(81, 221, 255, 1)"},attrs:{"offset":"100%"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }