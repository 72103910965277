<template>
  <section class="section">
    <div class="introduce-wrapper">
      <div class="title center">{{ introduce.title }}</div>
      <div class="introduce">
        <div class="introduce-pk">
          <!-- <img :src="introduce.pkBg" class="img-responsive block m-auto" /> -->
          <div class="introduce-bg">
            <div class="introduce-bg-l"></div>
            <div class="introduce-bg-c"></div>
            <div class="introduce-bg-r"></div>
          </div>
          <div class="introduce-pk-inner">
            <div class="introduce-pk-inner-l introduce-pk-common">
              <svg-icon :url="introduce.tradition.icon" class-name="introduce-pk-common-icon"></svg-icon>
              <div class="introduce-pk-inner-l-r introduce-pk-common-r">
                <div class="introduce-pk-common-title">
                  {{ introduce.tradition.title }}
                </div>
                <div class="introduce-pk-common-type">
                  {{ introduce.tradition.type }}
                </div>
                <div class="introduce-pk-common-price" v-html="introduce.tradition.price"></div>
              </div>
            </div>
            <svg-icon :icon-class="introduce.pkIcon" class-name="introduce-pk-inner-c"></svg-icon>
            <div class="introduce-pk-inner-r introduce-pk-common">
              <svg-icon :url="introduce.current.icon" class-name="introduce-pk-common-icon"></svg-icon>
              <div class="introduce-pk-inner-r-r introduce-pk-common-r">
                <div class="introduce-pk-common-title">
                  {{ introduce.current.title }}
                </div>
                <div class="introduce-pk-common-type">
                  {{ introduce.current.type }}
                </div>
                <div class="introduce-pk-common-price" v-html="introduce.current.price"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="special-wrapper">
        <div class="special-one" v-for="(title, index) in introduce.advantages" :key="index">
          <svg-icon :icon-class="introduce.advantagesIcon" class-name="special-one-icon"></svg-icon>
          <div class="special-one-title">{{ title }}</div>
        </div>
      </div>
      <!-- <img :src="introduce.special" class="img-responsive special" /> -->
    </div>
  </section>
</template>

<script>
import { introduce } from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'
export default {
  name: 'Introduce',
  mixins: [checkIsMobile],
  computed: {
    introduce() {
      const { mAdvantages, advantages } = introduce
      const temp = this.isMobile ? mAdvantages : advantages
      return { ...introduce, advantages: temp }
    },
  },
}
</script>

<style scoped lang="less">
.introduce-wrapper {
  background-image: url('../assets/images/introduce-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 120px;
  position: relative;
  .title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 38px;
    padding-top: 75px;
    padding-bottom: 50px;
  }
  .special-wrapper {
    position: absolute;
    box-sizing: border-box;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @panelWidth;
    box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
    border-radius: 2px;
    border: 2px solid #ffffff;
    display: flex;
    flex-wrap: wrap;
    padding: 35px 0;
    background-image: url('../assets/images/special-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .special-one {
      width: calc((100% - 200px) / 5);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 18px;
      &-icon {
        color: #2761ff;
        width: 24px;
        margin-right: 16px;
      }
      &-title {
        padding: 18px 0;
        white-space: nowrap;
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
    }
  }
}
.introduce {
  &-pk {
    position: relative;
    .introduce-bg {
      width: @panelWidth;
      display: flex;
      height: 160px;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
      border-radius: 2px;
      border: 2px solid #ffffff;
      margin: 0 auto;
      &-l {
        flex: 1;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: #ffffff;
      }
      &-c {
        border-left: 80px solid #ffffff;
        border-top: 80px solid #ffffff;
        border-right: 80px solid #2761ff;
        border-bottom: 80px solid #2761ff;
      }
      &-r {
        flex: 1;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        background-color: #2761ff;
      }
    }
    &-common {
      display: flex;
      align-items: center;
      min-width: 300px;
      &-r {
        margin-left: 16px;
      }
      &-icon {
        width: 80px;
        height: 80px;
      }
      &-title {
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        line-height: 27px;
      }
      &-type {
        padding: 4px 12px;
        text-align: center;
        margin-top: 8px;
        max-width: 120px;
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        line-height: 20px;
      }
      &-price {
        white-space: nowrap;
        padding-top: 4px;
        max-width: 180px;
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #2761ff;
        line-height: 27px;
      }
    }
    &-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      &-l {
        .introduce-pk-common-title {
          color: #000;
        }
        .introduce-pk-common-type {
          background: linear-gradient(180deg, #eaf0f4 0%, rgba(233, 239, 244, 0) 100%);
          box-shadow: 0px 1px 3px 0px #dbdbed;
          border-radius: 2px;
          color: #86909c;
        }
        .introduce-pk-common-price {
          color: #86909c;
        }
      }
      &-c {
        width: 153px;
        height: 153px;
      }
      &-r {
        .introduce-pk-common-title {
          color: #ffffff;
        }
        .introduce-pk-common-type {
          background: linear-gradient(180deg, #2059f4 0%, rgba(32, 89, 244, 0) 100%);
          box-shadow: 0px 1px 3px 0px rgba(33, 86, 232, 1);
          color: #ffffff;
        }
        .introduce-pk-common-price {
          color: #ffffff;
          min-width: 140px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .introduce-wrapper {
    padding-bottom: 44px;
    padding-left: 40px;
    padding-right: 40px;
    .title {
      font-size: 36px;
      line-height: 50px;
      padding-top: 88px;
      padding-bottom: 32px;
    }
    .special-wrapper {
      width: 100%;
      position: relative;
      top: inherit;
      left: inherit;
      transform: inherit;
      margin: inherit;
      padding: 50px 0;
      display: flex;
      .special-one {
        width: 33.3333%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        &-icon {
          font-size: 24px;
          padding: 16px;
          color: #2761ff;
        }
        &-title {
          flex: 1;
          white-space: nowrap;
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 27px;
        }
        // &:last-child {
        //   width: inherit;
        //   display: contents;
        // }
      }
    }
  }
  .introduce {
    padding: 40px 0;
    &-pk {
      position: relative;
      .introduce-bg {
        width: 100%;
        box-sizing: border-box;
        &-c {
          border-left: 73px solid #ffffff;
          border-top: 73px solid #ffffff;
          border-right: 73px solid #2761ff;
          border-bottom: 73px solid #2761ff;
        }
      }
      &-common {
        min-width: auto;
        display: flex;
        align-items: center;
        // min-width: 70%;
        justify-content: center;
        &-r {
          margin-left: 0px;
          // min-width: 70%;
        }
        &-icon {
          font-size: 80px;
        }
        &-title {
          font-size: 22px;
          font-family: AlibabaPuHuiTiR;
          line-height: 33px;
        }
        &-type {
          padding-top: 8px;
          max-width: 141px;
          line-height: 30px;
          font-size: 22px;
          font-family: AlibabaPuHuiTiR;
          line-height: 20px;
        }
        &-price {
          padding-top: 10px;
          max-width: 173px;
          font-size: 22px;
          white-space: nowrap;
        }
      }
      &-inner {
        width: 95%;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        &-l {
          flex: 1;
          margin-left: 0px;
          .introduce-pk-common-title {
            color: #000;
          }
          .introduce-pk-common-type {
            background: none;
            box-shadow: none;
            color: #86909c;
          }
          .introduce-pk-common-price {
            color: #86909c;
          }
        }
        &-c {
          font-size: 153px;
        }
        &-r {
          flex: 1;
          .introduce-pk-common-title {
            color: #ffffff;
          }
          .introduce-pk-common-type {
            border-radius: 2px;
            color: #ffffff;
          }
          .introduce-pk-common-price {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
