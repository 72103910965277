<template>
  <div class="cm-result">
    <el-dialog
      title=""
      :visible.sync="cmResultVisible"
      destroy-on-close
      custom-class="cm-result-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="cm-result-dialog-body">
        <img
          class="close-icon"
          src="@/assets/images/home/close.png"
          alt=""
          @click="
            () => {
              closeCmResult()
            }
          "
        />
        <div class="title">测名结果</div>
        <div class="cm-result-content">
          <div class="cm-info">
            <div class="cm-dashboard-box">
              <el-progress
                type="dashboard"
                class="cm-dashboard"
                :percentage="score"
                :stroke-width="6"
                :show-text="false"
              ></el-progress>
              <div class="score">
                <div>评分</div>
                <div>{{ score }}</div>
              </div>
              <div class="level">{{ analysisData.jx }}</div>
            </div>
            <div class="cm-info-name">{{ cmParams.companyName }}</div>
            <div class="cm-info-btn" @click="goHm" v-if="cmParams.city">
              去核名 <img src="@/assets/images/home/go.png" alt="" />
            </div>
          </div>
          <div class="cm-analysis">
            <div
              class="cm-analysis-item"
              v-for="(item, index) in analysisList"
              :key="index"
            >
              <div class="cm-analysis-item-label">{{ item.name }}</div>
              <div class="cm-analysis-item-content">
                {{ analysisData[item.code] }}
              </div>
            </div>
          </div>
          <div class="name-table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column
                :prop="item.code"
                :label="item.label"
                :align="'center'"
                :border="false"
                v-for="item in columns"
                :key="item.code"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="cm-unscramble">
            <div class="header-line" v-if="unscrambleList.length >= 6"></div>
            <div class="cm-unscramble-header">
              <div
                class="cm-unscramble-header-item"
                :class="{
                  'active-cm-unscramble-header-item':
                    activeUnscramble === index,
                }"
                v-for="(item, index) in unscrambleList"
                :key="index"
                @click="activeUnscramble = index"
              >
                {{ item.title }}
              </div>
            </div>
            <div
              class="cm-unscramble-content"
              v-if="
                unscrambleList[activeUnscramble] &&
                unscrambleList[activeUnscramble].content
              "
            >
              <p
                v-for="(cItem, cIndex) in unscrambleList[activeUnscramble]
                  .content"
                :key="'cItem' + cIndex"
              >
                {{ cItem }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <svg width="100%" height="100%">
      <defs>
        <linearGradient id="write-svg" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color: rgba(40, 113, 255, 1)"></stop>
          <stop offset="100%" style="stop-color: rgba(81, 221, 255, 1)"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
export default {
  props: ['cmResultVisible', 'cmResult', 'cmParams'],
  data() {
    return {
      score: 0,
      analysisList: [
        {
          name: '数理',
          code: 'sl',
        },
        {
          name: '法人数理暗示',
          code: 'frslas',
        },
        {
          name: '商业数理暗示',
          code: 'syslas',
        },
      ],
      analysisData: {},
      columns: [
        {
          label: '公司名',
          code: 'gsm',
        },
        {
          label: '拼音',
          code: 'py',
        },
        {
          label: '繁体',
          code: 'ft',
        },
        {
          label: '笔画',
          code: 'bh',
        },
        {
          label: '五行',
          code: 'wx',
        },
        {
          label: '吉凶',
          code: 'jx',
        },
      ],
      tableData: [],
      unscrambleList: [],
      activeUnscramble: 0,
      // cmResult: {},
    }
  },
  created() {},
  watch: {
    cmResult: {
      deep: true,
      handler() {
        if (this.cmResultVisible) {
          this.tableData = this.cmResult.ztwx
          this.analysisData = this.cmResult.yljx
          this.score = Number(this.cmResult.yljx.pf.replace(/[^0-9]/gi, ''))
          this.unscrambleList = this.cmResult.dzjx
        }
      },
    },
  },
  methods: {
    closeCmResult(cmParams) {
      this.$emit('closeCmResult', cmParams)
      this.tableData = []
      this.analysisData = {}
      this.score = 0
      this.unscrambleList = []
      this.activeUnscramble = 0
    },
    goHm() {
      this.closeCmResult(this.cmParams)
    },
  },
}
</script>
<style scoped lang="less">
.cm-result {
  /deep/ .cm-result-dialog {
    width: 923px;
    height: 535px;
    background: #ffffff;
    border-radius: 18px;
    .el-dialog__header {
      // height: 0px !important  ;
      // padding: 0;
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      .cm-result-dialog-body {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          padding: 40px 0px 0px 35px;
          box-sizing: border-box;
        }
        .title::before {
          content: '';
          display: block;
          width: 4px;
          height: 19px;
          background: #2871ff;
          border-radius: 2px;
          margin-right: 10px;
        }
        .cm-result-content {
          flex: 1;
          height: 0px;
          margin-top: 20px;
          margin-bottom: 20px;
          overflow-y: auto;
          .cm-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            .cm-dashboard-box {
              display: flex;
              justify-content: center;
              position: relative;
              .score {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 30px;
                // left: calc(50% - 28px);
                div:nth-of-type(1) {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #1a1a1a;
                  opacity: 0.5;
                }
                div:nth-of-type(2) {
                  font-size: 48px;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #2871ff;
                }
              }
              .level {
                background: #f6f7fb;
                border-radius: 17px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2871ff;
                position: absolute;
                bottom: 5px;
                text-align: center;
                padding: 3px 18px;
                box-sizing: border-box;
              }
            }
            .cm-info-name {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #1a1a1a;
              margin-top: 8px;
            }
            .cm-info-btn {
              margin-top: 12px;
              width: 85px;
              height: 24px;
              background: linear-gradient(90deg, #1663ff 0%, #2871ff 100%);
              border-radius: 23px;
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                margin-top: 2px;
                margin-left: 5px;
                width: 15px;
              }
            }
          }
          .cm-analysis {
            margin-top: 10px;
            padding: 0px 96px;
            box-sizing: border-box;
            .cm-analysis-item {
              display: flex;
              padding: 10px 0px;
              box-sizing: border-box;
              .cm-analysis-item-label {
                width: 78px;
                min-width: 78px;
                margin-right: 16px;
                font-size: 13px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #102a55;
                line-height: 18px;
              }
              .cm-analysis-item-content {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #102a55;
                line-height: 18px;
              }
            }
          }
          .name-table {
            margin-top: 16px;
            padding: 0px 96px;
            box-sizing: border-box;
            .el-table {
              .el-table__header-wrapper {
                .el-table__header {
                  thead {
                    tr {
                      th {
                        background: #f3f5fd;
                        font-size: 13px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2871ff;
                        height: 38px;
                        padding: 0px;
                        box-sizing: border-box;
                      }
                    }
                  }
                }
              }
              .el-table__body-wrapper {
                .el-table__body {
                  tbody {
                    tr {
                      background: rgba(236, 240, 251, 0.2);
                      font-size: 13px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #1a1a1a;
                      td {
                        height: 38px;
                        padding: 0px;
                        box-sizing: border-box;
                      }
                    }
                  }
                }
              }
              .el-table__cell {
                border: 0;
              }
            }
            .el-table::before {
              content: none;
            }
          }
          .cm-unscramble {
            margin-top: 34px;
            // margin-bottom: 60px;
            padding: 0px 96px;
            box-sizing: border-box;
            position: relative;

            .cm-unscramble-header {
              height: 38px;
              background: #f3f5fd;
              display: flex;
              align-items: center;
              overflow-x: auto;
              overflow-y: hidden;
              .cm-unscramble-header-item {
                min-width: 126px;
                text-align: center;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.5);
              }
              .active-cm-unscramble-header-item {
                color: #2871ff;
                position: relative;
              }
              .active-cm-unscramble-header-item::before {
                content: '';
                display: block;
                width: 30px;
                height: 2px;
                background: #2871ff;
                border-radius: 1px;
                position: absolute;
                bottom: -11px;
                left: calc(50% - 15px);
              }
            }
            .header-line {
              width: 20px;
              height: 38px;
              position: absolute;
              top: 0px;
              right: 96px;
              background: linear-gradient(
                90deg,
                rgba(243, 245, 253, 0.05) 0%,
                #dfe3f4 100%
              );
            }
            // 美化滚动条
            .cm-unscramble-header::-webkit-scrollbar {
              height: 4px;
            }
            .cm-unscramble-header::-webkit-scrollbar-thumb {
              background-color: #e3e3e3;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              border-radius: 3px;
              cursor: pointer;
            }
            .cm-unscramble-header {
              -ms-overflow-style: none;
            }
            .cm-unscramble-header {
              overflow: -moz-scrollbars-none;
            }
            .cm-unscramble-content {
              margin-top: 16px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 0.7);
              line-height: 18px;
            }
          }
        }
        // 美化滚动条
        .cm-result-content::-webkit-scrollbar {
          width: 4px;
        }
        .cm-result-content::-webkit-scrollbar-thumb {
          background-color: #e3e3e3;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
  }
}
.cm-dashboard /deep/ svg > path:nth-child(2) {
  stroke: url(#write-svg);
}
/deep/.el-progress-circle__track {
  stroke: #ecfbfd;
}
</style>
