<template>
  <section class="section">
    <div class="question-wrapper">
      <div class="title center">{{ question.title }}</div>
      <div class="relative">
        <img :src="question.bg" class="img-responsive bg" />
        <div class="question">
          <div class="question-list">
            <div class="question-one" v-for="item in question.list" :key="item.key">
              <svg-icon :icon-class="item.icon" class-name="question-one-l" />
              <div class="question-one-title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { question } from '@/config'

export default {
  name: 'Question',
  computed: {
    question() {
      return question
    },
  },
}
</script>

<style scoped lang="less">
@media screen and (min-width: 768px) {
  .question-wrapper {
    margin-top: -23px;
    .title {
      margin-bottom: 50px;
      font-size: 28px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 38px;
    }
  }
  .bg {
    min-height: 410px;
  }
  .question {
    position: absolute;
    width: @panelWidth;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-one {
      display: flex;
      align-items: center;
      width: calc((100% - 20px) / 2);
      margin-bottom: 20px;
      height: 143px;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
      border-radius: 2px;
      border: 2px solid #ffffff;
      box-sizing: border-box;
      background-image: url('../assets/images/question-list-one-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 25px 45px;
      box-sizing: border-box;
      &-l {
        width: 90px;
        height: 90px;
      }
      &-title {
        flex: 1;
        padding-left: 29px;
        text-align: left;
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 25px;
        width: 338px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .question-wrapper {
    margin-top: 88px;
    .title {
      font-size: 36px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 50px;
      margin-bottom: 44px;
    }
    .relative {
      background-color: #fafbfe;
      padding-top: 20px;
      padding-bottom: 20px;
      .bg {
        display: none;
      }
      .question-list {
        margin: 40px;
        display: grid;
        grid-gap: 20px;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        text-align: center;

        .question-one {
          background-image: url('../assets/images/question-list-one-bg.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
          border-radius: 2px;
          border: 2px solid #ffffff;
          padding: 50px;
          &-l {
            text-align: center;
            margin: 0 auto;
            font-size: 126px;
          }
          &-title {
            line-height: 33px;
            padding-top: 18px;
            font-size: 24px;
            font-family: AlibabaPuHuiTiR;
            color: #000000;
          }
        }
      }
    }
  }
}
</style>
