<template>
  <section class="section">
    <div class="advantage-wrapper m-auto">
      <div class="header-line" v-if="!isMobile"></div>
      <div class="header-title" v-if="!isMobile"><span>响应税局税收体制体系改革，专为小规模企业量身打造，倡导企业用户共同参与自助便捷的记账申报服务</span></div>
      <div class="advantage">
        <div class="advantage-one" v-for="item in advantages" :key="item.key">
          <div class="advantage-one-l">
            <svg-icon :url="item.icon" />
          </div>
          <div class="advantage-one-r">
            <div class="advantage-one-r-title">{{ item.title }}</div>
            <div class="advantage-one-r-desc" v-html="item.subTitle"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { advantages } from '@/config'
import checkIsMobile from '@/mixin/checkIsMobile'

export default {
  name: 'Advantage',
  mixins: [checkIsMobile],
  computed: {
    advantages() {
      return advantages
    },
  },
}
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .advantage-wrapper {
    box-sizing: border-box;
    position: relative;
    top: -95px;
    width: @panelWidth;
    height: 190px;
    background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
    box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
    border-radius: 2px;
    border: 2px solid #ffffff;
  }
  .header-line {
    width: @panelWidth;
    height: 1px;
    position: absolute;
    top: -41px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 30%, #ffffff 71%, rgba(255, 255, 255, 0) 100%);
  }
  .header-title {
    width: @panelWidth;
    height: 40px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    position: absolute;
    top: -40px;
    left: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      font-family: AlibabaPuHuiTiL;
      color: #000000;
      line-height: 20px;
    }
  }
  .advantage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 130px);
    display: flex;
    &-one {
      flex: 1;
      display: flex;
      border-right: 1px solid #e3e8ee;
      padding: 0 40px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        border: none;
      }
      &-l {
        width: 64px;
      }
      &-r {
        flex: 1;
        margin-left: 24px;
        &-title {
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 27px;
        }
        &-desc {
          line-height: 20px;
          margin-top: 12px;
          letter-spacing: 1px;
          font-size: 14px;
          font-family: AlibabaPuHuiTiR;
          color: #86909c;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .advantage-wrapper {
    top: 0px;
    padding: 0 40px;
  }
  .advantage-one {
    margin-top: 40px;
    display: flex;
    padding: 46px 36px;
    background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
    box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
    border-radius: 2px;
    border: 2px solid #ffffff;
    align-items: center;
    &-l {
      width: 72px;
      padding-right: 20px;
    }
    &-r {
      flex: 1;
      &-title {
        font-size: 28px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 38px;
      }
      &-desc {
        padding-top: 8px;
        font-size: 22px;
        font-family: AlibabaPuHuiTiR;
        color: #86909c;
        line-height: 30px;
      }
    }
  }
}
</style>
