var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"the-name-result"},[_c('el-dialog',{attrs:{"title":"","visible":_vm.theNameResultVisible,"destroy-on-close":"","custom-class":"the-name-result-dialog","close-on-click-modal":false,"close-on-press-escape":false,"show-close":false},on:{"update:visible":function($event){_vm.theNameResultVisible=$event}}},[_c('div',{staticClass:"the-name-result-dialog-body"},[_c('img',{staticClass:"close-icon",attrs:{"src":require("@/assets/images/home/close.png"),"alt":""},on:{"click":() => {
            _vm.closeTheNameResult()
          }}}),_c('div',{staticClass:"title"},[_vm._v("为你推荐")]),_c('div',{staticClass:"list"},_vm._l((_vm.nameList),function(item,index){return _c('div',{key:index,staticClass:"item",class:{ 'mouseover-item': _vm.isMouseover === item.words },on:{"mouseover":() => {
              _vm.isMouseover = item.words
            },"mouseout":() => {
              _vm.isMouseover = ''
            }}},[_c('div',{staticClass:"name"},[_c('span',[_vm._v(_vm._s(item.companyName.split(item.words)[0]))]),_c('span',[_vm._v(" "+_vm._s(item.words)+" ")]),_c('span',[_vm._v(_vm._s(item.companyName.split(item.words)[1]))]),(index < 3)?_c('div',{staticClass:"recommend"},[_vm._v("推荐")]):_vm._e()]),_c('div',{staticClass:"cm-btn",on:{"click":function($event){return _vm.goCm(item)}}},[_vm._v("去测名")])])}),0),_c('div',{staticClass:"btns"},[(_vm.nameList.length === 16)?_c('div',{staticClass:"btn",on:{"click":_vm.updateQm}},[_c('img',{attrs:{"src":require("@/assets/images/home/refresh.png"),"alt":""}}),_vm._v("换一批 ")]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }