<template>
  <div class="name-check-result">
    <el-dialog
      title=""
      :visible.sync="nameCheckResultVisible"
      destroy-on-close
      custom-class="name-check-result-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="name-check-result-dialog-body">
        <img
          class="close-icon"
          src="@/assets/images/home/close.png"
          alt=""
          @click="closeNameCheckResult"
        />
        <div class="title">核名结果</div>
        <div class="name-check-result-content">
          <img src="@/assets/images/home/validation.png" alt="" />
          <div class="label">
            核名通过率 <span>{{ passRate }}</span>
          </div>
          <div class="hint">您可以使用下方名称进入小程序，申请工商注册啦</div>
          <div class="name-btn">{{ hmParams.companyName }}</div>
        </div>
        <div class="footer">
          <div class="footer-label">
            * 本次数据仅供参考，以工商局最终审核结果为准
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
export default {
  props: ['nameCheckResultVisible', 'hmParams', 'hmResult'],
  data() {
    return {
      passRate: '',
    }
  },
  created() {},
  watch: {
    hmResult() {
      if (this.nameCheckResultVisible) {
        this.passRate = this.hmResult
      }
    },
  },
  methods: {
    closeNameCheckResult() {
      this.$emit('closeNameCheckResult')
      this.passRate = ''
    },
  },
}
</script>
<style scoped lang="less">
.name-check-result {
  /deep/ .name-check-result-dialog {
    width: 923px;
    height: 535px;
    background: #ffffff;
    border-radius: 18px;
    .el-dialog__header {
      // height: 0px !important  ;
      // padding: 0;
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      .name-check-result-dialog-body {
        height: 100%;
        padding-bottom: 30px;
        box-sizing: border-box;
        position: relative;
        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          padding: 40px 0px 0px 35px;
          box-sizing: border-box;
        }
        .title::before {
          content: '';
          display: block;
          width: 4px;
          height: 19px;
          background: #2871ff;
          border-radius: 2px;
          margin-right: 10px;
        }
        .name-check-result-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-top: 24px;
            width: 100px;
            height: 112px;
          }
          .label {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1a1a1a;
            span {
              color: rgba(40, 113, 255, 1);
            }
          }
          .hint {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            margin: 12px 0px 43px;
            opacity: 0.5;
          }
          .name-btn {
            width: 451px;
            height: 62px;
            background: #f6f9fe;
            border-radius: 10px;
            text-align: center;
            line-height: 62px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1a1a1a;
          }
        }
        .footer {
          width: 100%;
          position: absolute;
          bottom: 20px;
          .footer-label {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            opacity: 0.5;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
