<template>
  <div class="the-name-result">
    <el-dialog
      title=""
      :visible.sync="theNameResultVisible"
      destroy-on-close
      custom-class="the-name-result-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="the-name-result-dialog-body">
        <img
          class="close-icon"
          src="@/assets/images/home/close.png"
          alt=""
          @click="
            () => {
              closeTheNameResult()
            }
          "
        />
        <div class="title">为你推荐</div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in nameList"
            :key="index"
            :class="{ 'mouseover-item': isMouseover === item.words }"
            @mouseover="
              () => {
                isMouseover = item.words
              }
            "
            @mouseout="
              () => {
                isMouseover = ''
              }
            "
          >
            <div class="name">
              <span>{{ item.companyName.split(item.words)[0] }}</span>
              <span>&nbsp;{{ item.words }}&nbsp;</span>
              <span>{{ item.companyName.split(item.words)[1] }}</span>
              <div class="recommend" v-if="index < 3">推荐</div>
            </div>
            <div class="cm-btn" @click="goCm(item)">去测名</div>
          </div>
        </div>
        <div class="btns">
          <div class="btn" @click="updateQm" v-if="nameList.length === 16">
            <img src="@/assets/images/home/refresh.png" alt="" />换一批
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
export default {
  props: ['theNameResultVisible', 'nameList'],
  data() {
    return {
      timer: null,
      isMouseover: '',
    }
  },
  created() {},
  watch: {},
  methods: {
    closeTheNameResult(item) {
      this.$emit('closeTheNameResult', item)
    },
    updateQm() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.nameList.length === 16) {
            this.$emit('updateQm')
            clearTimeout(this.timer)
            this.timer = null
          }
        }, 500)
      } else {
        this.timer = setTimeout(() => {
          if (this.nameList.length === 16) {
            this.$emit('updateQm')
            clearTimeout(this.timer)
            this.timer = null
          }
        }, 500)
      }
    },
    goCm(item) {
      this.closeTheNameResult(item)
    },
  },
}
</script>
<style scoped lang="less">
.the-name-result {
  /deep/ .the-name-result-dialog {
    width: 923px;
    height: 535px;
    background: #ffffff;
    border-radius: 18px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      .the-name-result-dialog-body {
        height: 100%;
        padding-bottom: 30px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;

        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          padding: 40px 0px 0px 35px;
          box-sizing: border-box;
        }
        .title::before {
          content: '';
          display: block;
          width: 4px;
          height: 19px;
          background: #2871ff;
          border-radius: 2px;
          margin-right: 10px;
        }
        .list {
          margin-top: 15px;
          padding: 0px 40px 0px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          // flex: 1;
          // height: 0px;
          .item {
            flex: 1;
            min-width: 40%;
            max-width: 47%;
            height: 60px;
            padding: 20px 16px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            .name {
              color: #1a1a1a;
              display: flex;
              align-items: center;
              span:nth-of-type(2) {
                color: rgba(40, 113, 255, 1);
              }
              .recommend {
                width: 30px;
                height: 18px;
                background: linear-gradient(
                  146deg,
                  rgba(255, 85, 84, 0.11) 0%,
                  rgba(253, 43, 42, 0.18) 100%
                );
                border-radius: 5px;
                border: 1px solid #ffadad;
                box-sizing: border-box;
                font-size: 10px;
                color: #fd2b2a;
                line-height: 16px;
                text-align: center;
                margin-left: 9px;
              }
            }
            .cm-btn {
              width: 71px;
              height: 27px;
              border-radius: 45px;
              border: 1px solid #2871ff;
              text-align: center;
              line-height: 27px;
              color: #2871ff;
              cursor: pointer;
            }
          }
          .item:nth-of-type(odd) {
            margin-right: 47px;
          }
          .item:hover {
            background: #f6f9fe;
            border-radius: 10px;
          }
          .mouseover-item {
            background: #f6f9fe;
            border-radius: 10px;
          }
        }
        // 美化滚动条
        .list::-webkit-scrollbar {
          width: 4px;
        }
        .list::-webkit-scrollbar-thumb {
          background-color: #e3e3e3;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer;
        }
        .btns {
          margin-top: 20px;
          // margin-bottom: 30px;
          .btn {
            margin: 0px auto;
            width: 160px;
            height: 45px;
            background: #2871ff;
            border-radius: 8px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 9px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
