<template>
  <section class="section">
    <div class="charact-wrapper">
      <div class="title center">{{ characterInfo.title }}</div>
      <div class="charact m-auto">
        <div class="charact-one" v-for="item in characterInfo.list" :key="item.key">
          <svg-icon :url="item.icon" class-name="icon"></svg-icon>
          <div class="charact-one-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { characterInfo } from '@/config'

export default {
  name: 'Character',
  computed: {
    characterInfo() {
      return characterInfo
    },
  },
}
</script>

<style scoped lang="less">
.charact-wrapper {
  background-color: #fafbfe;
  padding-bottom: 70px;
  .title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 38px;
    padding-top: 72px;
    padding-bottom: 50px;
  }
  .charact {
    width: @panelWidth;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &-one {
      box-sizing: border-box;
      display: flex;
      max-height: 88px;
      align-items: center;
      margin: 10px;
      padding: 20px 50px;
      width: calc(25% - 20px);
      box-sizing: border-box;
      max-width: 25%;
      background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
      box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
      border: 2px solid #ffffff;
      img {
        max-width: 48px;
      }
      &-title {
        flex: 1;
        padding-left: 16px;
        font-size: 20px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 27px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .charact-wrapper {
    padding: 0 20px;
    padding-bottom: 44px;
    .title {
      font-size: 32px;
      line-height: 44px;
      padding-top: 88px;
      padding-bottom: 44px;
    }
    .charact {
      width: 100%;
      display: grid;
      grid-gap: 20px;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
      &-one {
        display: flex;
        align-items: center;
        padding: 20px 60px;
        box-sizing: border-box;
        width: auto;
        max-width: inherit;
        background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
        box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
        border: 2px solid #ffffff;
        .icon {
          width: 48px;
        }
        &-title {
          flex: 1;
          text-align: left;
          white-space: nowrap;
          padding-left: 16px;
          font-size: 20px;
          font-family: AlibabaPuHuiTiR;
          color: #000000;
          line-height: 27px;
        }
      }
    }
  }
}
</style>
